import {
    EonUiForm,
    EonUiPreloaderCircle,
} from "@eon-ui/eon-ui-components-react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TileDataInterface } from "../../interfaces/TileInterface";
import "./SearchList.scss";
import ListItem from "./ListItem";
import useClickOutside from "../../util/useClickOutside";
import useSearchStore from "../../store/searchStore";
import useOutsideFocus from "../../util/useOutsideFocus";
import { useFeedback } from "../feedback/feedback";
import { useAuth } from "../../MsalContext";

export interface Questionator {
    title: string;
}

const SearchList = ({
    isChatBotSearch,
    itemClickedCallback,
    queryChange,
    limitedHeight,
    listReverse,
    defaultInputVal,
    disabledSearch,
    indicies,
}: {
    isChatBotSearch?: boolean;
    itemClickedCallback?: (query: string) => void;
    queryChange?: (query: string) => void;
    limitedHeight?: boolean;
    listReverse?: boolean;
    defaultInputVal?: null | string;
    disabledSearch?: boolean;
    indicies?: string[];
}) => {
    const {
        loading,
        isEditMode,
        isSearchFocused,
        setInputFocused,
        fetchSearchData,
        fetchAdvanceSearchData,
        resultUpdate,
        data: searchData,
        questionairData,
    } = useSearchStore();
    const { t, i18n } = useTranslation();
    const [query, setQuery] = useState(defaultInputVal || "");
    const navigate = useNavigate();
    const feedbackFn = useFeedback();
    const { isLimitedAccess } = useAuth();
    const [topOffset, setTopOffset] = useState(-46);
    const listRef = useRef<HTMLUListElement>(null);
    const listExistClass = listReverse ? "reverse-list--exist" : "list--exist";

    useEffect(() => {
        setQuery(defaultInputVal || "");
    }, [defaultInputVal]);

    useEffect(() => {
        if (disabledSearch) {
            queryChange && queryChange(query);
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            queryChange && queryChange(query);
            if (query.trim() !== "" && query.length > 1) {
                if (isChatBotSearch) {
                    fetchAdvanceSearchData(
                        query,
                        indicies || [],
                        i18n.language
                    );
                } else {
                    fetchSearchData(query);
                    fetchAdvanceSearchData(
                        query,
                        [
                            "q-azureblob-test",
                            "actions-prod",
                            "office-buddy-prod",
                            "apps-prod",
                        ],
                        i18n.language
                    );
                }
            }
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
            resultUpdate([]);
        };
    }, [query]);

    useEffect(() => {
        setQuery(defaultInputVal || "");
    }, [defaultInputVal]);

    useLayoutEffect(() => {
        if (listRef.current && listReverse) {
            setTopOffset(
                document.getElementsByClassName("result_ul")[0].clientHeight
            );
        }
    });

    const inputSubmit = (e: any) => {
        if (isChatBotSearch) {
            if (itemClickedCallback) {
                itemClickedCallback(query);
                setQuery("");
            }
            return;
        }
        feedbackFn({ source: "query", page: "simple", query });

        e.preventDefault();
        if (!isLimitedAccess) {
            iconClickHandler();
        }
    };

    const iconClickHandler = () => {
        if (query && !isLimitedAccess) {
            navigate(`/search-result?q=${query}`);
        }
    };
    const onClickOutside = () => {
        setInputFocused(false);
    };

    const listItemClicked = (
        question: string,
        isFromquestionairData?: string
    ) => {
        setQuery("");
        if (itemClickedCallback) {
            itemClickedCallback(question);
        }
        if (!isChatBotSearch && isFromquestionairData === "questionairData") {
            // need to check if we have to call below function
            // feedbackFn({ source: "query", page: "simple", query: question });
            navigate(`/search-result?q=${question}`);
        }
    };

    useClickOutside("search-input", onClickOutside);
    const listWrapper = useOutsideFocus<HTMLDivElement>(() => {
        setInputFocused(false);
    });

    return (
        <div
            id="search-input"
            ref={listWrapper}
            className={`search-list-component ${
                isSearchFocused ? "input--focused" : ""
            }
                ${
                    query.length > 1 &&
                    isSearchFocused && (searchData.length > 0 || questionairData.length > 0)
                        ? listExistClass
                        : ""
                }`}
        >
            <EonUiForm onFormSubmit={inputSubmit}>
                <input
                    id="tileTour1"
                    className="input-box-wrapper"
                    type="text"
                    value={query}
                    // onBlur={() => setTimeout(() => {
                    //     dispatch(setInputFocused(false));
                    // }, 500)}
                    autoComplete="off"
                    onFocus={() => setInputFocused(true)}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={t("searchPage.searchPlaceholder")}
                    title={t("searchPage.searchApps")}
                    disabled={isEditMode}
                />
                {loading && (
                    <div className="loader">
                        <EonUiPreloaderCircle size="small" />
                    </div>
                )}
                {!isLimitedAccess && !isChatBotSearch && (
                    <button
                        className="search-icon-container"
                        onClick={iconClickHandler}
                        disabled={isEditMode}
                    ></button>
                )}
            </EonUiForm>
            {query.length > 1 &&
                isSearchFocused &&
                (searchData.length > 0 || questionairData.length > 0) && (
                    <ul
                        style={{ top: -topOffset }}
                        ref={listRef}
                        className={`result_ul
                        result_ul${limitedHeight ? "limited-height" : ""}
                        ${
                            listReverse
                                ? "result_ul--reverse"
                                : "result_ul--normal"
                        }
                    `}
                    >
                        {searchData.length > 0 && (
                            <div className="app-section">
                                {!isChatBotSearch && (
                                    <div className="result-search-subheader">
                                        {t("landingPage.searchApps")}
                                    </div>
                                )}
                                <div className="app-slider">
                                    {searchData.map(
                                        (
                                            result:
                                                | TileDataInterface
                                                | Questionator,
                                            index: number
                                        ) => (
                                            <ListItem
                                                resultData={result}
                                                key={`${index + 1}`}
                                                listItemClicked={(question) =>
                                                    listItemClicked(question)
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        )}

                        {questionairData && questionairData.length > 0 && (
                            <>
                                {!isChatBotSearch && (
                                    <div className="result-search-subheader">
                                        {t("landingPage.suggestions")}
                                    </div>
                                )}
                                {questionairData.map(
                                    (
                                        result:
                                            | TileDataInterface
                                            | Questionator,
                                        index: number
                                    ) => (
                                        <ListItem
                                            resultData={result}
                                            key={`${index + 1}`}
                                            listItemClicked={(question) =>
                                                listItemClicked(
                                                    question,
                                                    "questionairData"
                                                )
                                            }
                                        />
                                    )
                                )}
                            </>
                        )}
                    </ul>
                )}
        </div>
    );
};

export default SearchList;
