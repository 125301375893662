import {useTranslation} from "react-i18next";
import {FilterSetters} from "./elsearch.domain";
import {DataType} from "./ElSearchAdvanceHelpers";
import {EonUiLink} from "@eon-ui/eon-ui-components-react";
import EnvironmentConfig from "../../config/EnvironmentConfig";

type SelectFilterButtonProps = {
    isReset?: boolean;
    setters: FilterSetters;
    textkey: string;
    clazz?: string;
    dataType: DataType;
    value: string[] | "app" | "m365" | "jql" | "assistance";
    id?: "default";
    clearActiveFilter?: () => void;
};

export function SelectFilterButton({
                                       id,
                                       isReset,
                                       setters,
                                       clazz,
                                       value,
                                       textkey,
                                       dataType,
                                       clearActiveFilter,
                                   }: SelectFilterButtonProps) {
    const {
        setIsLoading,
        setResultsToDisplay,
        setResultCount,
        setDataType,
        setSearchIndex,
        setAdditionalFilters,
        setScrollCompleted,
    } = setters;

    function filterHandler() {
        setIsLoading(true);
        setScrollCompleted(false);
        setResultsToDisplay([]);
        setSearchIndex([]);
        setResultCount(0);
        setDataType(dataType);
        clearActiveFilter && clearActiveFilter();
        let index: string[];
        if (value === "app") {
            //TODO This is the place that will need to be refactored.
            index = [EnvironmentConfig.appIndex.toString()];
        } else if (typeof value === 'string') {
            index = [value];
        } else if (Array.isArray(value)) {
            index = value;
        } else {
            index = [];
        }
        setTimeout(() => {
            if (value.length > 0) {
                setAdditionalFilters({});
            }
            setSearchIndex(index);
        });
    }

    const {t} = useTranslation();

    return isReset ? (
        <EonUiLink
            placeholder="reload-img"
            icon="reload"
            className="reload-icon"
            onClick={filterHandler}
            title={t("filterPage.filterMenu.reset")}
            size="normal"
            href="javascript:void(0)"
            scheme="red500"
        ></EonUiLink>
    ) : (
        <button id={id} className={`tablinks ${clazz}`} onClick={filterHandler}>
            {t(textkey)}
        </button>
    );
}
