import {TimeService} from "../domain/useTime";
import {
    calcDataRangeFilters,
    CalcDataRangeFilters,
    extractMetadataFiltersFromAxiosResponse,
    extractResultCountFromAxiosResponse,
    ExtractResultFn,
    extractResultsFromAxiosResponse,
    extractResultThen,
    mostRelevant,
} from "./search";
import {AxiosStatic} from "axios";
import {filterQuery, FilterQuery} from "../domain/info";
import {QueryTypeAnd} from "../interfaces/ElasticSearchInterface";
import EnvironmentConfig from "../config/EnvironmentConfig";

export type RawCallElasticSearchFn = (
    url: string,
    body: any,
    apiKey: string | null
) => Promise<any>;
export type ApiKeyFn = () => string | null;

export function defaultApiKeyFn(): string | null {
    return localStorage.getItem("dls-apikey");
}

export type SearchContext = {
    timeService: TimeService;
    apikey: ApiKeyFn;
    indicies: string;
    elasticsearchUrl: (indicies: string, explain?: boolean) => string;
    rawCallElasticSearch: RawCallElasticSearchFn;
    extractResults: QueryTypeAnd<ExtractResultFn>;
    extractResultCount?: (response: any) => any;
    extractMetadataFilters: (response: any) => any;
    calcDataRangeFilters: CalcDataRangeFilters;
    filterQuery: FilterQuery;
    boosts?: string[];
};

//  elasticSearchDomain is likely to be one of
//  https://c3224bc073f74e73b4d7cec2bb0d5b5e.westeurope.azure.elastic-cloud.com:9243/
// https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243/
export const defaultSearchContext = (
    axios: AxiosStatic,
    apiKey?: string
): SearchContext => ({
    timeService: () => new Date(),
    apikey: () => apiKey ?? defaultApiKeyFn(),
    indicies: EnvironmentConfig.indicies as string,
    elasticsearchUrl: (indicies, explain?: boolean) => {
        const suffix = explain ? "_explain" : `_search`;
        const actualIndicies = explain ? indicies.split(",")[0] : indicies;
        return `${EnvironmentConfig.elasticSearchBaseUrl}/${actualIndicies}/${suffix}`;
    },
    rawCallElasticSearch: async (url: string, body: any, apiKey) =>
        axios.post(url, body, {
            headers: {Authorization: `ApiKey ${apiKey}`},
        }),
    extractResults: {
        knn: extractResultsFromAxiosResponse,
        boostAndPhrase: extractResultsFromAxiosResponse,
        keywords: extractResultThen(
            extractResultsFromAxiosResponse,
            mostRelevant(0.7)
        ),
    },
    extractResultCount: extractResultCountFromAxiosResponse,
    extractMetadataFilters: extractMetadataFiltersFromAxiosResponse,
    calcDataRangeFilters,
    filterQuery,
    boosts: [
        // "full_text", deliberately removed
        `issue`, //the boosts are all removed
        `title`,
        `name`,
        `card_title`,
    ],
});

