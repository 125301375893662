import ApiCall from "./ApiCall";
import { AxiosError } from "axios";
import EnvironmentConfig from "../config/EnvironmentConfig";
import { OAuth2Token } from "./OAuth2Token";

export const myApprovalCount = async (payload: {
    access_token: string;
}): Promise<any> => {
    return await ApiCall(
        "api/approval/getTaskCollectionCount",
        "post",
        payload
    );
};

export const getAuthorizationUrl = (): string => {
    if (
        !EnvironmentConfig.myApprovalOAuthUrl ||
        !EnvironmentConfig.myApprovalOAuthTokenUrl ||
        !EnvironmentConfig.myApprovalOAuthClientId ||
        !EnvironmentConfig.myApprovalOAuthClientSecret
    ) {
        return "";
    }
    const oAuth2Token = new OAuth2Token()
        .setAuthUrl(EnvironmentConfig.myApprovalOAuthUrl as unknown as string)
        .setClientId(
            EnvironmentConfig.myApprovalOAuthClientId as unknown as string
        )
        .setRedirectUri(
            EnvironmentConfig.myApprovalOAuthRedirectUrl as unknown as string
        )
        .setScope(EnvironmentConfig.myApprovalOAuthScope as unknown as string);
    return oAuth2Token.getAuthorizationUrl();
};

export const getAccessToken = async (): Promise<string | null> => {
    try {
        const cachedData = localStorage.getItem("myApprovalToken");
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            if (isTokenValid(parsedData)) {
                return parsedData.access_token;
            }
        }
        const urlParams = new URLSearchParams(window.location.search);
        const authorizationCode = urlParams.get("code");
        if (authorizationCode) {
            const oAuth2Token = new OAuth2Token()
                .setAuthTokenUrl(
                    EnvironmentConfig.myApprovalOAuthTokenUrl as unknown as string
                )
                .setRedirectUri(
                    EnvironmentConfig.myApprovalOAuthRedirectUrl as unknown as string
                )
                .setClientId(
                    EnvironmentConfig.myApprovalOAuthClientId as unknown as string
                )
                .setClientSecret(
                    EnvironmentConfig.myApprovalOAuthClientSecret as unknown as string
                );
            const data = await oAuth2Token.getAccessToken(authorizationCode);
            if (data) {
                const expiry = new Date().getTime() + 25 * 60000;
                data.expiryAt = expiry;
                localStorage.setItem("myApprovalToken", JSON.stringify(data));
                return data?.access_token;
            }
        }
        return null;
    } catch (error) {
        if (
            error instanceof AxiosError &&
            error.response?.data?.error &&
            error.response?.data?.error === "invalid_grant"
        ) {
            const authUrl = getAuthorizationUrl();
            if (authUrl) {
                window.location.href = authUrl;
            }
        }
        console.error("Error fetching access token:", error);
        return null;
    }
};

const isTokenValid = ({
    expiryAt,
    access_token,
}: {
    expiryAt: string;
    access_token: string;
}): boolean => {
    if (!access_token || !expiryAt) {
        return false;
    }
    const parsedExpiryAt = parseInt(expiryAt, 10);
    if (isNaN(parsedExpiryAt)) {
        return false;
    }
    const timeDiff = parsedExpiryAt - Date.now();
    return timeDiff > 0;
};
