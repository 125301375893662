import {EonUiInput} from "@eon-ui/eon-ui-components-react";
import {DropDownInfo} from "../../domain/info";
import {FilterSetters} from "./elsearch.domain";
import {DataSourceFilter} from "../../interfaces/ElasticSearchInterface";

export type SearchInputProps = {
    searchTerm: string;
    setSearchTerm: (value: string) => void;
};

export function SearchInput({
                                searchTerm,
                                setSearchTerm,
                            }: Readonly<SearchInputProps>) {
    return (
        <EonUiInput
            type="text"
            trailingIconName="search"
            placeholder="Search..."
            value={searchTerm}
            onValueChanged={(e) => setSearchTerm(e.target.value)}
        />
    );
}

export type DataType = "" | "all" | "apps" | "allWithFilter" | 'm365' | "jql" | "assistance";

export type SelectDataTypeProps = {
    options: DropDownInfo[];
    dataType: DataType;
    setters: FilterSetters;
    dataSourceFilters: DataSourceFilter[];
};