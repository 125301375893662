import axios from "axios";
import {defaultApiKeyFn} from "../search/searchContext";
import EnvironmentConfig from "../config/EnvironmentConfig";

export const getQuestionData = async (searchTerm: string, indicies: string[], language?: string) => {
    const indiciesString = indicies.join(",");
    const apiKey = defaultApiKeyFn();

    const url =  `${EnvironmentConfig.elasticSearchBaseUrl}/${indiciesString}/_search`;

    return axios
        .post(
            url,
            {
                query: {
                    match_phrase_prefix: {
                        keyfield: searchTerm,
                    },
                },
            },
            {
                headers: {
                    Authorization:
                        `ApiKey ${apiKey}`,
                },
            }
        )
        .then((response) => {
            const filteredSuggestions = response.data.hits.hits.filter((hit: any) => {
                return hit._source?.type !== "content";
            });

            return filteredSuggestions.map((hit: any) => {
                if(language && hit._source?.t?.question[language]) {
                    return {title: hit._source.t.question[language]};
                }
                else {
                    return {title: hit._source.keyfield};
                }
            });
        })
        .catch((error) => {
            console.error("Fetch Error :-S", error);
            return error;
        });
};
