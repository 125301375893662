import { useEffect, useState } from "react";
import "./MeetingPopup.scss";
import {
    EonUiButton,
    EonUiInput,
    EonUiIcon,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import AvailabilityTimeline from "../availability-timeline/AvailabilityTimeline";
import { EonUiInputCustomEvent } from "@eon-ui/eon-ui-components";
import {
    CapatityObjInterface,
    ItemInterface,
    UnavailabilityDataInterface,
    UnavailabilityDataType,
} from "./RoomBookingInterface";
import unavailabilityJson from "./unavailabilityJSON.json";
import useRoomBookingStore from "../../store/roomBookingStore";

interface IMeetingPopupProps {
    isRoomBooking?: boolean;
    toggleBookingRoomModal: () => void;
    saveBookingRoom: (meetingTopic: string) => void;
    unavailabilityInfo: UnavailabilityDataInterface[];
}

const MeetingPopup = ({
    isRoomBooking,
    toggleBookingRoomModal,
    saveBookingRoom,
    unavailabilityInfo,
}: IMeetingPopupProps) => {
    const { clickedCardData, numberOfParticipants } = useRoomBookingStore();
    const { t } = useTranslation("room-booking");
    const [meetingTopic, setMeetingTopic] = useState("");
    const [desc, setDesc] = useState("");
    const [invalidInput, setInvalidInput] = useState(false);
    const [showAvailability, setShowAvailability] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const lang: string =
        localStorage.getItem("i18nextLng") === "en" ? "en" : "de";

    const unavailabilityData: UnavailabilityDataType = unavailabilityJson;
    const validateBooking = () => {
        if (meetingTopic) {
            saveBookingRoom(meetingTopic);
        } else {
            setInvalidInput(true);
        }
    };

    useEffect(() => {
        setShowAvailability(false);
        unavailabilityInfo[0]?.items.map((range: ItemInterface) => {
            if (range.unavailability_type_id === 11) {
                // booking exist
                setShowAvailability(true);
                setErrorMsg("");
            } else {
                const typeId: number = range.unavailability_type_id || 0;
                setErrorMsg(`${unavailabilityData[lang][typeId]}`);
            }
        });
    }, [unavailabilityInfo]);

    const changeMeetingTitle = (value: string) => {
        setMeetingTopic(value);
        setInvalidInput(false);
    };

    return (
        <div className="meeting-popup">
            {clickedCardData &&
                typeof clickedCardData.minCapValue === "number" &&
                clickedCardData.minCapValue > 0 &&
                clickedCardData.minCapValue <
                    parseInt(numberOfParticipants) && (
                    <div className="room-not-available">
                        <EonUiIcon
                            size="normal"
                            name="alert"
                            className="error-icon eon-ui-svg-icon"
                        />
                        <span className="error-text">
                            {t("participantError")}{" "}
                            {parseInt(numberOfParticipants)}{" "}
                            {t("participantErrorMsg")}{" "}
                            {clickedCardData.minCapValue}!
                        </span>
                    </div>
                )}
            {clickedCardData.availability === 2 && (
                <div className="room-not-available">
                    <EonUiIcon
                        size="normal"
                        name="alert"
                        className="error-icon eon-ui-svg-icon"
                    />
                    <span className="error-text">
                        {t("roomBooked")}
                    </span>
                </div>
            )}
            {errorMsg && (
                <div className="room-not-available">
                    <EonUiIcon
                        size="normal"
                        name="alert"
                        className="error-icon eon-ui-svg-icon"
                    />
                    <span className="error-text">{errorMsg}</span>
                </div>
            )}
            {clickedCardData.availability === 1 && (
                <div className="room-partially-available">
                    <EonUiIcon
                        size="normal"
                        name="alert"
                        className="partial-error-icon eon-ui-svg-icon"
                    />
                    <span className="error-text">
                        {t("roomPartiallyBooked")}
                    </span>
                </div>
            )}

            {showAvailability && (
                <AvailabilityTimeline unavailabilityInfo={unavailabilityInfo} />
            )}

            {!isRoomBooking && (
                <div className="form-wrapper">
                    <EonUiInput
                        type="text"
                        size="small"
                        className="tab-input"
                        onValueChanged={(e: EonUiInputCustomEvent<any>) =>
                            changeMeetingTitle(e.target.value)
                        }
                        invalid={invalidInput}
                        error-message={"This field is Required"}
                        placeholder={t(
                            "titleOfMeetingPlaceholder"
                        )}
                        label={t("titleOfMeetingLabel")}
                        labelOutside
                    />
                    <EonUiInput
                        type="text"
                        size="small"
                        className="tab-input"
                        value={desc}
                        label={t(
                            "meetingDescriptionLabel"
                        )}
                        labelOutside
                        onValueChanged={(e: EonUiInputCustomEvent<any>) =>
                            setDesc(e.target.value)
                        }
                        placeholder={t(
                            "descOfMeetingPlaceholder"
                        )}
                    />
                    <div>
                        <label className="eon-form-label">
                            {t("meetingLocationLabel")}
                        </label>
                        <div className="field-value">
                            {clickedCardData?.object_name}
                        </div>
                    </div>
                    <div>
                        <div className="capacity_wrapper">
                            <EonUiIcon
                                name="user_group_alt"
                                size="small"
                                className="capacity_icon eon-ui-svg-icon"
                            />
                            <label className="eon-form-label">
                                {t("capacityLabel")}{" "}
                                {clickedCardData.seating_ids.length === 1 &&
                                    `(${clickedCardData.minCapValue})`}
                            </label>
                        </div>
                        <div className="field-value">
                            {clickedCardData && clickedCardData.seating_ids && clickedCardData.seating_ids.length > 1 &&
                                clickedCardData.seating_ids.map(
                                    (
                                        seatingObj: CapatityObjInterface,
                                        idx: number
                                    ) => {
                                        return (
                                            <div key={`${idx.toString()}`}>
                                                {seatingObj.seating_type_name} |{" "}
                                                {seatingObj.capacity_max}
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                    <div className="common-button-wrapper">
                        <EonUiButton
                            fullWidthOnMobile
                            text={t("cancel")}
                            rank="secondary"
                            size="small"
                            onClick={() => {
                                toggleBookingRoomModal();
                            }}
                        />
                        <EonUiButton
                            fullWidthOnMobile
                            text={t("confirmBookingBtn")}
                            rank="primary"
                            size="small"
                            disabled={
                                clickedCardData.availability === 2 ||
                                clickedCardData.availability === 1 ||
                                (clickedCardData.minCapValue ? clickedCardData.minCapValue <
                                    parseInt(numberOfParticipants) : false)
                            }
                            onClick={() => {
                                validateBooking();
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MeetingPopup;
