import otherConfigs from "./OtherConfigs";
import {Env, EnvConfigs} from "./ConfigInterface";
import myApprovalConfigs from "./MyApprovalConfig";


const envConfig: EnvConfigs = {
    local: {
        gatewayURL: "https://dev-me8on-mvp.eon.tools",
        apikeyUrl: "https://dev-me8on-mvp.eon.tools/apikey",
        // apikeyUrl: "http://localhost:1236/apikey",
        gingcoURL: "https://dev-me8on-mvp.eon.tools",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/dev",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    dev: {
        gatewayURL: "https://dev-me8on-mvp.eon.tools",
        apikeyUrl: "https://dev-me8on-mvp.eon.tools/apikey",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/dev",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    qa: {
        gatewayURL: "https://qa-me8on-mvp.eon.tools",
        apikeyUrl: "https://qa-me8on-mvp.eon.tools/apikey",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/preprod",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    pp: {
        gatewayURL: "https://pp-me.eon.tools",
        apikeyUrl: "https://pp-me.eon.tools/apikey",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/preprod",
        gingcoRedirectURL: "https://eon.gingco-estate.com",
    },
    prod: {
        gatewayURL: "https://me.eon.tools",
        apikeyUrl: "https://me.eon.tools/apikey",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        serviceUrl: "https://api.eon.com/edt/itsm",
        gingcoRedirectURL: "https://eon.gingco-estate.com",
    },
};

let env: Env;
switch (process.env.REACT_APP_ENV) {
    case "dev":
        env = "dev";
        break;
    case "qa":
        env = "qa";
        break;
    case "pp":
        env = "pp";
        break;
    case "prod":
        env = "prod";
        break;
    default:
        env = "local";
        break;
}

export function elasticSearchWebUrl() {
    const esUrl = envConfig[env].elasticSearchBaseUrl;
    if (esUrl.includes("f0571c62")) return "https://me8eon-elasticsearch-test.kb.westeurope.azure.elastic-cloud.com:9243/";
    if (esUrl.includes("c3224bc0")) return "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/";
    throw new Error(`Unknown Elastic Search URL ${esUrl}`);
}

export default {...envConfig[env], ...otherConfigs[env], ...myApprovalConfigs[env]};
