import {createContext, ReactNode, useCallback, useEffect, useState} from "react";
import useAiData, {IAiAssistData} from "./hooks/useAiData";
import {Message} from "../ai/ai.types";
import {OutputAndSource} from "../ai/ai.rag.client";

export interface MessageWithActionAndData extends Message {
    action?: string;
    data?: any;
}

interface IContextValues {
    handleChangeCurrentCardKey: (key: string) => () => void;
    currentCardKey: string;
    handleAiQuery: (query: string) => void;
    messages: MessageWithActionAndData[];
    isLoading: boolean;
    cards: IAiAssistData;
    handleResetChat: () => void;
    setMessages?: (messages: MessageWithActionAndData[]) => void;
    chatMode: number
}

const AiAssistContext = createContext<IContextValues>({
    handleChangeCurrentCardKey: () => () => {
    },
    currentCardKey: "",
    messages: [],
    handleAiQuery: () => {
    },
    isLoading: false,
    cards: {},
    handleResetChat: () => {
    },
    setMessages: () => {
    },
    chatMode: 1
});

const AiAssistProvider = ({children}: { children: ReactNode }) => {
    const cards: IAiAssistData = useAiData();
    const [messages, setMessages] = useState<MessageWithActionAndData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentCardKey, setCurrentCardKey] = useState("selfService");
    const [chatMode, setChatMode] = useState(1);

    const handleChangeCurrentCardKey = useCallback((key: string) => () => {
            setChatMode(key === 'internetSearch' ? 2 : 1);
            setMessages([]);
            setCurrentCardKey(key);
        }
        , [setCurrentCardKey]);

    const handleAiQuery = useCallback((query: string) => {
        if(query.trim().length > 0 ) {
            setMessages([...messages, {
                role: "user",
                action: "user-query",
                content: query,
            }]);
            setIsLoading(true);
            }
        }
        , [cards, currentCardKey]);

    const handleAiAssist = useCallback(async (query: string) =>
            await cards[currentCardKey].handleAiQuery(messages, query)
        , [cards, currentCardKey]);

    useEffect(() => {
        if (messages.length === 0) return;
        if (messages[messages.length - 1].role === "assistant") return;

        const getResponse = async () => {
            const query = messages[messages.length - 1].content;
            const response: OutputAndSource = await handleAiAssist(query);

            setMessages([...messages, {
                role: "assistant",
                content: response.output,
                action: response?.action,
                data: response?.data,
            }]);
            setIsLoading(false);
        };
        getResponse().then(r => r);
    }, [messages]);

    const handleResetChat = useCallback(() => setMessages([]), [setMessages]);

    return (
        <AiAssistContext.Provider
            value={{
                handleChangeCurrentCardKey,
                currentCardKey,
                handleAiQuery,
                messages,
                setMessages,
                isLoading,
                cards,
                handleResetChat,
                chatMode
            }}>
            {children}
        </AiAssistContext.Provider>
    );
};

export {AiAssistContext, AiAssistProvider};