import "./QuickActionLinks.scss";
interface QuickActionLinksProps {
    actionItems: string[];
    onItemClick?: (item: string) => void;
}

const QuickActionLinks = ({
    actionItems: items,
    onItemClick,
}: QuickActionLinksProps) => {
    const handleClick = (item: string) => {
        console.log("Clicked item:", item);
        if (onItemClick) {
            onItemClick(item);
        }
    };

    return (
        <ul className="quick-links-wrapper">
            {items.map((item, index) => (
                <li
                    className="quick-link-list"
                    key={index}
                    onClick={() => handleClick(item)}
                >
                    {item}
                </li>
            ))}
        </ul>
    );
};

export default QuickActionLinks;
