import axios from "axios";
import EnvironmentConfig from "../config/EnvironmentConfig";

interface ResponseData {
    id: string;
    name: string;
    api_key: string;
    encoded: string;
    admin: boolean;
    username: string;
    indicies: string;
    query: object;
}

async function fetchAndStoreDLSApiKey(account: {
    username: string;
    idToken?: string;
}) {
    try {
        const url = `${EnvironmentConfig.apikeyUrl}/${account.username}`;
        const resp = await axios.get<ResponseData>(
            url,
            {
                headers: {
                    Authorization: `Bearer ${account.idToken}`,
                },
            }
        );
        localStorage.setItem("dls-apikey", resp?.data?.encoded);
        localStorage.setItem('admin', resp?.data?.admin?.toString() || 'false');
    } catch (err) {
        console.log(err);
    }
}

export default fetchAndStoreDLSApiKey;
