import { useContext } from "react";
import { AiAssistContext } from "../AiAssist.context";

const SliderButton = () => {
    const { handleChangeCurrentCardKey, chatMode, currentCardKey } =
        useContext(AiAssistContext);
    const handleButtonClick = (key: string) => {
        if (currentCardKey !== key) {
            return handleChangeCurrentCardKey(key);
        }
    };

    return (
        <div className="button-toggle">
            <button
                className={`opt-button ${
                    chatMode === 1 ? "opt-button--active" : ""
                }`}
                onClick={handleButtonClick("selfService")}
            >
                Ask E.ON
            </button>
            <button
                className={`opt-button ${
                    chatMode === 2 ? "opt-button--active" : ""
                }`}
                onClick={handleButtonClick("internetSearch")}
            >
                Ask Internet
            </button>
        </div>
    );
};

export default SliderButton;
