import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { Link } from "react-router-dom";
import { TileDataInterface } from "../../interfaces/TileInterface";
import { useTranslation } from "react-i18next";
import SVGIcon from "../svg-icon/SVGIcon";
import MyApprovalCount from "./MyApprovalCount";

const TileContent = ({
    isEditMode,
    data,
    handleDeleteTile,
}: {
    isEditMode: boolean;
    data: TileDataInterface;
    handleDeleteTile: (tile: TileDataInterface) => void;
}) => {
    const { t } = useTranslation();
    const { alias, title, id, target, icon } = data;
    const isCustomIcon = icon?.endsWith(".svg");
    const myInboxAlias = "processing";
    let translatedLabel = alias ? t(`appData.${alias}`) : title;
    if (translatedLabel.startsWith("appData.")) {
        translatedLabel = title;
    }

    const resolveForEditMode = (elem: any) => {
        return isEditMode ? (
            <>{elem}</>
        ) : (
            <Link
                title={t(`appData.${alias}hover`)}
                key={title}
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                to={target ?? ""}
                className="tile-link"
                onClick={(event) => isEditMode && event.preventDefault()}
            >
                {elem}
            </Link>
        );
    };

    return (
        <>
            {resolveForEditMode(
                <div className="tile-body">
                    {!isCustomIcon ? (
                        <EonUiIcon
                            placeholder="tile-img"
                            size="normal"
                            className="tile-icon eon-ui-svg-icon"
                            key={"tile-" + id}
                            name={icon}
                        ></EonUiIcon>
                    ) : (
                        <SVGIcon
                            className="tile-icon"
                            url={`/icons/${alias}.svg`}
                            key={alias}
                        />
                    )}
                    <div className="tile-text-wrapper">
                        <div className="tile-title">{translatedLabel}</div>
                        <div className="tile-subtext">
                            {t(
                                `appData.subTitle.${alias}SubTitle`
                            ).toUpperCase()}
                        </div>
                        {alias === myInboxAlias && <MyApprovalCount />}
                    </div>
                </div>
            )}
            <button
                className={`close-icon ${
                    isEditMode ? "close-icon--editmode" : ""
                }`}
                onClick={() => handleDeleteTile(data)}
                disabled={isEditMode}
            >
                <EonUiIcon
                    title="Delete"
                    placeholder="trash-icon"
                    key={"trash-" + data.id}
                    className="trash-icon eon-ui-svg-icon"
                    size="small"
                    name="trash_bin"
                ></EonUiIcon>
            </button>
        </>
    );
};
export default TileContent;
