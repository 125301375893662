import {getFrom, MapOrDefault} from "../../util/map";
import EnvironmentConfig from "../../config/EnvironmentConfig";

export type QuestionatorSearchResult = {
    index: string;
    type: string;
    question: string;
    keyfield: string;
    answer: string;
    content: string;
};
export type AppsProdSearchResult = {
    index: string;
    title: string;
    target: string;
};

export type TGenerateAiResponseProps<T> = {
    query: string;
    searchResult: T,
    language: string;
};

export type TAzureBlobSearchResult = {
    _index: string,
    _id: string,
    t: { question: { [key: string]: string }, answer: { [key: string]: string } },
    summary: string,
    keyfield: string,
    answer: string,
    title: string,
    url: string,
}

export type TGenerateAiResponseFn<T> = (props: TGenerateAiResponseProps<T>) => string;

export const getPromptForOfficeBuddyProd: TGenerateAiResponseFn<QuestionatorSearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<QuestionatorSearchResult>) => {
        return `
            Matched Q&A:
            Question: ${searchResult.keyfield}
            Answer: ${searchResult.answer}
            
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with general answer. 
            
            INSTRUCTIONS: 
            1) See Matched Q&A. There is the Answer to the user's question.
            2) IMPORTANT! Always if user question includes some personal conditions (for example: 
            Where is *my laptop*? Where is *my office*?) respond with general answer. 
            3) If Matched Q&A includes URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language}`;
    };


export const questionatorPromptTemplates: MapOrDefault<TGenerateAiResponseFn<QuestionatorSearchResult>> = {
    qanda: ({searchResult, language}) => ` Matched Q&A:
                Question: ${searchResult.keyfield}
                Answer: ${searchResult.answer}
                                
            -- START DOCUMENT --
             ${(searchResult as any)["content"]}    
            -- END DOCUMENT --
                
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched Q&A with content. Q&A that is returned 
            from ElasticSearch has been built using custom "Questioner" app based on the input 
            PDF Doc

            INSTRUCTIONS:
            1) See Matched Q&A. There is the Answer to the user's question.
            2) If Matched Q&A includes URL, keep URL in the response.
            3) Review the Full document content and include it in the response if needed.
            3a) If document has information in points, you can also response in points.
            3b) If document has URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: ${language}`,
    content: ({searchResult, language}) => `
            -- START DOCUMENT CONTENT --
            ${(searchResult as any)["keyfield"]}   
            -- END DOCUMENT --
            
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched document with content.

            INSTRUCTIONS:
            1) See Full Document Content. There is the Answer to the user's question
            2) Ensure the response is in natural language, accurate and concise.
            3) If document has information in points, you can also response in points
            4) If you don't know the answer, just say you don't know.
            5) If Matched Q&A includes URL, keep URL in the response.
            
            Last step- Respond in language: ${language}`,
    default: () =>
        `INSTRUCTIONS: Answer to the user based only on the information provided above. 
            If user asks question regarding his own conditions (for example: Where is my laptop? 
            Where is my office?) treat it as general question. If document has information in points, 
            you can also response in points. If you don't know the answer, just say you don't know. 
            Ensure the response is accurate and concise. Respond in user's language, translate if needed.`,
};

export const getPromptForQuestionatorProd: TGenerateAiResponseFn<QuestionatorSearchResult> =
    ({
         query,
         searchResult,
         language,
     }: TGenerateAiResponseProps<QuestionatorSearchResult>) => getFrom(questionatorPromptTemplates)(searchResult.type)({
        query,
        searchResult,
        language
    });

export const getPromptForAppsProd: TGenerateAiResponseFn<AppsProdSearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<AppsProdSearchResult>) => {
        return ` ElasticSearch Results:
              Title: ${searchResult["title"]}
              Url: ${EnvironmentConfig.gatewayURL + searchResult["target"]}
              
              CONTEXT: You're company employee support agent. User asked a question that 
              went to the ElasticSearch and found matched title of the application that he is 
              looking for. Your job is to tell user the app name and its url that he is looking for. \\n\\n
  
              INSTRUCTIONS:\\n
              1) See the title of the application.\\n
              2) Generate short response based on the matched title and user's question.\\n
              2a) If title includes URL, keep URL in the response.\\n
              4) Ensure the response is in natural language, accurate and concise.\\n
              
              Last step- Respond in language: ${language}`;
    };

export const getPromptForAzureBlob: TGenerateAiResponseFn<TAzureBlobSearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<TAzureBlobSearchResult>) => {
        return ` Matched Q&A:
                Question: ${searchResult.keyfield}
                Answer: ${searchResult.answer}
                                
            -- START DOCUMENT --
             ${(searchResult as any)["summary"]}    
            -- END DOCUMENT --
                
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched Q&A with content. Q&A that is returned 
            from ElasticSearch has been built using custom "Questioner" app based on the input 
            PDF Doc from Azure Blob Storage. So source is Azure Blob Storage.

            INSTRUCTIONS:
            1) See Matched Q&A. There is the Answer to the user's question.
            2) If Matched Q&A includes URL, keep URL in the response.
            3) Review the Full document content and include it in the response if needed.
            3a) If document has information in points, you can also response in points.
            3b) If document has URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: "${language}" ${searchResult.url && `and include the link to source document: ${searchResult.url}`}`;
    };

export const getPromptForEdisConfluence: TGenerateAiResponseFn<TAzureBlobSearchResult> =
    ({
         searchResult,
         language,
     }: TGenerateAiResponseProps<TAzureBlobSearchResult>) => {
        return ` Matched Q&A:
                Question: ${searchResult.keyfield}
                Answer: ${searchResult.answer}
                                
            -- START DOCUMENT --
             ${(searchResult as any)["summary"]}    
            -- END DOCUMENT --
                
            CONTEXT: You're company employee support agent. User asked a question that 
            went to the ElasticSearch and found matched Q&A with content. Q&A is returned 
            from ElasticSearch has been built using custom "Questioner" app based on the input 
            Confluence Documents (like connector). So it's based on Confluence page.

            INSTRUCTIONS:
            1) See Matched Q&A. There is the Answer to the user's question.
            2) If Matched Q&A includes URL, keep URL in the response.
            3) Review the Full document content and include it in the response if needed.
            3a) If document has information in points, you can also response in points.
            3b) If document has URL, keep URL in the response.
            4) Ensure the response is in natural language, accurate and concise.
            
            Last step- Respond in language: "${language}" ${searchResult.url && `and include the link to the confluence page: ${searchResult.url}`}`;
    };