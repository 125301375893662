import parseLinks, {
    parsedLinkArray,
} from "../../ai-assist-refactored/utils/parseLinks";
import {
    AiClient,
    OutputAndSource,
    useAiWithRag,
} from "../../ai/ai.rag.client";
import RelatedApps from "./related-apps/RelatedApps";
import "./UnifiedSearch.scss";
import { useEffect, useState } from "react";
import { marked } from "marked";
import { FilterSetters } from "../elsearch.domain";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { TSetShowAiAssist } from "../../ai-assist-refactored/AiAssist";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon32.svg";
import LoaderComponent from "../../loader-component/LoaderComponent";

const AiChatBotResponse = ({ aiResponse }: { aiResponse: string[] }) => {
    const linksArray = parsedLinkArray(
        aiResponse.length > 0 ? aiResponse[0] : ""
    );
    return (
        <div className="role-list">
            {aiResponse.map(
                (aiResponse, index) =>
                    aiResponse && (
                        <div
                            className="unified-bot-text-format"
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: marked.parseInline(
                                    aiResponse
                                ) as string,
                            }}
                        />
                    )
            )}
            {linksArray && linksArray.length > 0 && (
                <div className="citation-container">
                    {linksArray.map((link, index) => (
                        <EonUiButton
                            size="small"
                            rank="secondary"
                            text={link.text ?? link.url}
                            onClick={() => window.open(link.url, "")}
                            key={index}
                            className="citation-item"
                        ></EonUiButton>
                    ))}
                </div>
            )}
        </div>
    );
};

const ShowMoreButton = ({
    setShowAiAssist,
}: {
    setShowAiAssist: TSetShowAiAssist;
}) => {
    const { t } = useTranslation();
    return (
        <EonUiButton
            title={t("chatBot.sendbtn")}
            text={t("chatBot.showMore")}
            placeholder={""}
            onClick={() => {
                setShowAiAssist(true);
                console.log("Send button clicked");
            }}
            className="show-more-button"
            icon="send"
            size="small"
        ></EonUiButton>
    );
};

const useSearchAiChatBot = (searchTerm: string) => {
    const ai: AiClient = useAiWithRag();
    const [response, setResponse] = useState<string | null>(null);
    const lang: string =
        localStorage.getItem("i18nextLng") === "en" ? "en" : "de";

    useEffect(() => {
        const fetchResponse = async () => {
            const result: OutputAndSource = await ai.aiClientWithRag(
                [],
                searchTerm,
                lang,
                [
                    "q-azureblob-test",
                    "actions-prod",
                    "office-buddy-prod",
                    "apps-prod",
                ]
            );
            setResponse(`${parseLinks(result.output)}`);
        };
        if (searchTerm) {
            fetchResponse();
        }
    }, [searchTerm]);

    return response;
};

const UnifiedSearch = ({
    searchTerm,
    setters,
    setShowAiAssist,
}: {
    searchTerm: string;
    setters: FilterSetters;
    setShowAiAssist: TSetShowAiAssist;
}) => {
    const aiResponse = useSearchAiChatBot(searchTerm);

    return (
        <div className="advance-unified-assist">
            <div className="related-apps-container">
                <RelatedApps searchTerm={searchTerm} setters={setters} />
            </div>
            {aiResponse ? (
                <div className="chatbot-response">
                    <div className="ai-logo">
                        <AiIcon />
                        <span>AI Assistant</span>
                    </div>
                    <AiChatBotResponse aiResponse={[aiResponse]} />
                    <ShowMoreButton setShowAiAssist={setShowAiAssist} />
                </div>
            ) : (
                <div className="no-response-loader">
                    <LoaderComponent isLoading={true} isLinear/>
                </div>
            )}
        </div>
    );
};
export default UnifiedSearch;
