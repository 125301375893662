import axios from "axios";
import AzureAiClientBase, {TAzureAiClientConstructor} from "./ai.base.client";
import {Message} from "../ai.types";
import {
    getPromptForAppsProd,
    getPromptForAzureBlob,
    getPromptForEdisConfluence,
    getPromptForOfficeBuddyProd,
    getPromptForQuestionatorProd,
    TGenerateAiResponseFn,
} from "../ai.prompts";
import {SearchFn} from "../../../search/search";
import {ESApiFinalResponseInterface} from "../../../interfaces/ElasticSearchInterface";
import {getFrom} from "../../../util/map";
import {OutputAndSource} from "../ai.rag.client";
import {defaultSearchContext} from "../../../search/searchContext";
import {knnSearch} from "../../../search/all.searchs";
import {AiActionByType} from "../ai.actions";

type AiRagConfig = {
    searchFn: SearchFn<ESApiFinalResponseInterface>;
    searchIndicies: string[];
    selectSearchResult: (
        searchResults: ESApiFinalResponseInterface[]
    ) => ESApiFinalResponseInterface;
    makeCustomPrompt: (history: Message[], query: string, role: Message["role"]) => Message[]
    makeOutputAndSource: (
        searchResultId: string | undefined,
        messages: Message[],
    ) => OutputAndSource;
};

export function defaultAiRagConfig(
    searchIndicies: string[],
    searchFn: SearchFn<ESApiFinalResponseInterface>
): AiRagConfig {
    return {
        searchIndicies,
        searchFn,
        selectSearchResult: (searchResults: ESApiFinalResponseInterface[]) =>
            searchResults.sort(questionAndKnnFirstComparator)[0],

        makeCustomPrompt: (history: Message[], query: string, role: Message["role"]) => [
            ...history,
            {role, content: query}],

        makeOutputAndSource: (
            searchResultId: string | undefined,
            messages: Message[],
            data?: any
        ): OutputAndSource => ({
            output: messages[0].content,
            source: searchResultId || "No search result found",
            action: "rag",
            data,
        }),
    };
}


export function questionAndKnnFirstComparator(a: any, b: any): number {
    if ("content" in a && !("content" in b)) {
        return 1;
    } else if (!("content" in a) && "content" in b) {
        return -1;
    } else {
        if (a?.queryType === "knn" && b?.queryType !== "knn") return -1;
        if (a?.queryType !== "knn" && b?.queryType === "knn") return 1;
        return 0;
    }
}

export interface IAiRagClient {
    config: TAzureAiClientConstructor;
    elasticApiKey?: string;
    searchIndicies?: string[];
}


export class AiRagClient extends AzureAiClientBase {
    protected elasticApiKey: string | undefined;
    private readonly searchContext: ReturnType<typeof defaultSearchContext>;
    private aiRagConfig: AiRagConfig;

    constructor({config, elasticApiKey, searchIndicies}: IAiRagClient) {
        super(config);
        this.elasticApiKey = elasticApiKey;
        this.searchContext = defaultSearchContext(axios, this.elasticApiKey);

        this.aiRagConfig = {
            ...defaultAiRagConfig(
                searchIndicies || [],
                knnSearch(this.searchContext)
            ),
        };
    }

    public async aiClientWithRag(history: Message[], query: string, language: string, indicies?: string[]) {
        const searchResults = await this.aiRagConfig.searchFn({
            searchTerm: query,
            searchIndexes: indicies || this.aiRagConfig.searchIndicies,
        });
        const searchResult = this.aiRagConfig.selectSearchResult(searchResults);

        const sendWithPreviousHistory = (messages: Message[]) => this.sendRequest([...history, ...messages]);

        const performRag = (getPromptFn: TGenerateAiResponseFn<any>) => async () => {
            const commonProps = {
                searchResult,
                query,
                language
            };
            const prompt = getPromptFn(commonProps);
            const message = [{role: "system", content: prompt}, {role: "user", content: query}] as Message[];
            const outputMessages = await sendWithPreviousHistory(message);
            return this.aiRagConfig.makeOutputAndSource(searchResult?.id, outputMessages);
        };

        const actionByIndex = {
            "actions-prod": () =>
                getFrom(AiActionByType)(searchResult.action)(sendWithPreviousHistory),
            "apps-prod": performRag(getPromptForAppsProd),
            "questionator-prod": performRag(getPromptForQuestionatorProd),
            "office-buddy-prod": performRag(getPromptForOfficeBuddyProd),
            "q-azureblob-test": performRag(getPromptForAzureBlob),
            "q-edis-confluence-prod": performRag(getPromptForEdisConfluence),
            default: async () => {
                throw new Error(`Index ${searchResult.index} not supported`);
            },
            undefined: async () => {
                throw new Error(`Index name is undefined`);
            }
        };
        return getFrom(actionByIndex)(searchResult.index)();
    }
}