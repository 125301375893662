import React from "react";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon32.svg";
import { MessageWithActionAndData } from "../AiAssist.context";
import TypingEffect from "../utils/typing-effect/TypingEffect";

const ragComponent: React.FC<MessageWithActionAndData> = ({
    content,
}: MessageWithActionAndData) => {
    return (
        <div className="bot-header">
            <div className="eon-logo">
                <AiIcon />
            </div>
            <div className="bot-reply-container">
                <span className="bot-heading">AI Assistant</span>
                <TypingEffect className="bot-text" text={content} speed={1} />
            </div>
        </div>
    );
};

export default ragComponent;
