import React from "react";
import {Message} from "./ai.types";
import {useJwtTokens} from "../diagnostics/diagnostics";
import environmentConfig from "../../config/EnvironmentConfig";
import SummariseToIncidentClientSingleton from "./clients/ai.createIncident.client";
import {defaultAzureAiConfig, TAzureAiClientConstructor} from "./clients/ai.base.client";
import {AiRagClient} from "./clients/ai.rag.client";

export type OutputAndSource = {
    output: string;
    source: string;
    action: string;
    data?: any;
};

export type AiClientWithRag = (history: Message[], query: string, language: string, indicies?: string[]) => Promise<OutputAndSource>
export type AiClientSummariseChatHistoryToIncident = (history: Message[], reason: string, language: string) => Promise<OutputAndSource>

export type AiClient = {
    aiClientWithRag: AiClientWithRag,
    aiClientSummariseChatHistoryToIncident: AiClientSummariseChatHistoryToIncident
}

const AiRagContext = React.createContext<AiClient | undefined>(
    undefined
);

export function AiRagProvider({children}: { children: React.ReactNode }) {
    const aiConfig: TAzureAiClientConstructor = {
        config: defaultAzureAiConfig(environmentConfig["gatewayURL"] + "/proxy/"),
        tokenOrApiKey: useJwtTokens().token || "",
    };

    const aiSummariseClient = new SummariseToIncidentClientSingleton(aiConfig);
    const aiRagClient = new AiRagClient({
        config: aiConfig,
        searchIndicies: ["questionator-prod", "office-buddy-prod", "apps-prod", "actions-prod"]
    });

    const aiClientSummariseChatHistoryToIncident = async (history: Message[], reason: string, language: string) => aiSummariseClient.summariseToIncident(history, reason, language);
    const aiClientWithRag: AiClientWithRag = async (history: Message[], query: string, language: string, indicies?: string[]) => aiRagClient.aiClientWithRag(history, query, language, indicies);

    return (
        <AiRagContext.Provider value={{aiClientSummariseChatHistoryToIncident, aiClientWithRag}}>
            {children}
        </AiRagContext.Provider>
    );
}

export function useAiWithRag(): AiClient {
    const client = React.useContext(AiRagContext);
    if (!client)
        throw new Error("useAiWithRag must be used within a AiRagProvider");
    return client;
}
