import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
    useRef,
} from "react";
import {msalInstance} from "./msalConfig";
import {AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import EnvironmentConfig from "./config/EnvironmentConfig";
import {getParameterByName} from "./util/common-util";

interface MsalContextType {
    account: AccountInfo | null | undefined;
    login: () => Promise<void>;
    logout: () => void;
    acquireToken: (
        scopes: AccountInfo,
        force: boolean
    ) => Promise<AuthenticationResult | null>;
    isExpired: boolean;
    isLimitedAccess: boolean;
    orgName: string;
}

const MsalContext = createContext<MsalContextType | undefined>(undefined);

interface MsalProviderProps {
    children: ReactNode;
}

export const MsalProvider: React.FC<MsalProviderProps> = ({ children }) => {
    const [account, setAccount] = useState<AccountInfo | null | undefined>(
        null
    );
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [isLimitedAccess, setIsLimitedAccess] = useState(false);
    const [orgName, setOrgName] = useState("");
    const awaitedLogin = useRef<boolean>(false);

    useEffect(() => {
        const initializeMsal = async () => {
            await msalInstance.initialize();
            const currentAccounts = msalInstance.getAllAccounts();
            if (currentAccounts.length > 0) {
                setAccount(currentAccounts[0]);
            } else {
                setAccount(undefined);
            }
        };
        initializeMsal();
    }, []);

    useEffect(() => {
        if (account) {
            const accessArray = EnvironmentConfig.AiAccess as string[];
            const roleArray = account.idTokenClaims?.roles || [];
            const isPresent = accessArray.some((r) => roleArray.includes(r));
            setIsLimitedAccess(!isPresent);
            const userMail = account.idTokenClaims?.email || account.username;

            // temp code to set org name
            if (getParameterByName("org")?.match("bayern")) {
                setOrgName("bayernwerk");
            } else if (userMail && typeof userMail === "string") {
                const orgFromEmail = userMail.split("@")[1].split(".")[0];
                // const orgFromEmail = "bayernwerk";
                setOrgName(orgFromEmail);
            }

            const checkAuth = () => {
                const {exp} = account.idTokenClaims || {};
                const expiryTime = exp ? exp * 1000 : 0;
                const currentTime = new Date().getTime();
                const isTokenExpired = expiryTime <= currentTime;
                setIsExpired(isTokenExpired);
                if (isTokenExpired) {
                    acquireTokenSilent(account, true);
                }
            };
            checkAuth();
            const interval = setInterval(checkAuth, 30 * 1000);
            return () => clearInterval(interval);
        }
    }, [account]);

    const acquireTokenSilent = async (account: AccountInfo, force: boolean) => {
        try {
            const response = await msalInstance.acquireTokenSilent({
                account,
                scopes: ["User.Read"],
                forceRefresh: force,
            });
            if (account.idToken !== response.idToken) {
                console.log("Token acquired silently:", response.idToken);
                setAccount(response.account);
                setIsExpired(false);
            }
            return response;
        } catch (error) {
            console.error("Silent token acquisition failed:", error);
            if ((error as any).name === "InteractionRequiredAuthError") {
                // Fallback to interactive login if silent acquisition fails
                if (!awaitedLogin.current) {
                    awaitedLogin.current = true;
                    await login();
                }
            }
            return null;
        }
    };

    const login = async () => {
        try {
            const loginResponse = await msalInstance.loginPopup({
                scopes: ["user.read"],
            });
            setAccount(loginResponse.account);
            setIsExpired(false);
        } catch (error) {
            console.error("Login failed:", error);
        } finally {
            awaitedLogin.current = false;
        }
    };

    const logout = async () => {
        await msalInstance.logoutPopup();
        setAccount(null);
        setIsExpired(false);
    };

    return (
        <MsalContext.Provider
            value={{
                account,
                login,
                logout,
                acquireToken: acquireTokenSilent,
                isExpired,
                isLimitedAccess,
                orgName,
            }}
        >
            {children}
        </MsalContext.Provider>
    );
};

export const useAuth = (): MsalContextType => {
    const context = useContext(MsalContext);
    if (!context) {
        throw new Error("useMsal must be used within an MsalProvider");
    }
    return context;
};
