import { t } from "i18next";
import useFirstName from "../hooks/useFirstName";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon64.svg";
import SliderButton from "./SliderButton";

const AiWelcomeWrapper = () => {
    const { firstName } = useFirstName();

    return (
        <>
            <div className="ai-assist-welcome">
                <AiIcon />
                <div>
                    <span
                        title={t("chatBot.topicStartMessage")}
                        key={t("chatBot.topicStartMessage")}
                        className="start-message"
                    >
                        {t("chatBot.hey")} {firstName},{" "}
                        {t("chatBot.topicStartMessage")}
                    </span>
                </div>
                <SliderButton />
            </div>
            {/* <div className="card-container">
                {cardsOrder.map((card) => (
                    <AiAssistCard
                        highlight={currentCardKey === card.key}
                        key={card.key}
                        message={cards[card.key]}
                        currentCardSetter={handleChangeCurrentCardKey(card.key)}
                    />
                ))}
            </div>
            <div className="ai-assist-content-wrapper">
                <MostRelevantSearch
                    relevantQuestion={cards[currentCardKey]}
                    relevantQuestionHandler={handleAiQuery}
                />
            </div> */}
        </>
    );
};

export default AiWelcomeWrapper;
