import React, { useCallback, useContext, useMemo } from "react";
import { EonUiPreloaderSquares } from "@eon-ui/eon-ui-components-react";
import { AiAssistContext, MessageWithActionAndData } from "../AiAssist.context";
import { lookupTableAboutChatbotActions } from "../action-components/actions";
import userQueryComponent from "../action-components/userQuery.component";
import { getFrom } from "../../../util/map";
import AiWelcomeWrapper from "./AiWelcome";
import { Feedback } from "../../feedback/feedback.component";
import { ChatbotFeedbackPayload, LikeOrDislike } from "../../feedback/feedback";
import LiveAgentChat from "../../live-agent-chat/LiveAgentChat";
import useSearchStore from "../../../store/searchStore";
import QuickActionLinks from "../QuickActionLinks";
import i18n from "../../../i18n";
import SliderButton from "./SliderButton";

const Content: React.FC = () => {
    const { showLiveAgentChat } = useSearchStore();

    const { messages, isLoading, cards, currentCardKey } =
        useContext(AiAssistContext);

    const showFeedback = useMemo(
        () => messages[messages.length - 1]?.role === "assistant" && !isLoading,
        [messages, isLoading]
    );
    const showCreateIncident = useMemo(
        () => cards[currentCardKey]?.feedback?.includes("create-incident"),
        [cards, currentCardKey]
    );

    const feedbackDataHandler = useCallback(
        (like: LikeOrDislike): ChatbotFeedbackPayload => {
            return {
                like,
                source: "chatbot",
                messages,
                query: messages[messages.length - 1]?.content || "",
            };
        },
        [messages]
    );

    const messageComponents = useMemo(
        () =>
            messages.map((message: MessageWithActionAndData, index: number) => {
                switch (message.role) {
                    case "user": {
                        const UserComponent = userQueryComponent;
                        return (
                            <UserComponent key={`user-${index}`} {...message} />
                        );
                    }
                    case "assistant": {
                        const AssistantComponent = getFrom(
                            lookupTableAboutChatbotActions
                        )(message.action);
                        if (!AssistantComponent) {
                            console.error(
                                `No component found for action: ${message.action}`
                            );
                            return null;
                        }
                        return (
                            <AssistantComponent
                                key={`assistant-${index}`}
                                {...message}
                            />
                        );
                    }
                    default:
                        throw new Error("Invalid role");
                }
            }),
        [messages]
    );

    return (
        <>
            {messages.length <= 0 ? (
                <AiWelcomeWrapper />
            ) : (
                <div className="ai-assist-content-wrapper">
                    <SliderButton />
                    <div className="ai-assist-content">
                        {messageComponents}
                        {isLoading && (
                            <EonUiPreloaderSquares
                                className="preloader"
                                placeholder="preloader"
                            />
                        )}
                        {showFeedback && (
                            <Feedback
                                dataToSend={feedbackDataHandler}
                                showCreateIncident={showCreateIncident}
                            />
                        )}
                    </div>
                </div>
            )}
            {showLiveAgentChat && <LiveAgentChat />}
        </>
    );
};

const AiMessages: React.FC = () => {
    const language = i18n.language as "en" | "de";
    const actionItemsList = {
        en: [
            "Raise an Incident",
            "Connect with Live Agent",
            "Register Critical Complaint",
        ],
        de: [
            "Raise an Incident",
            "Connect with Live Agent",
            "Register Critical Complaint",
        ],
    };
    return (
        <div className="ai-assist-wrapper">
            <div className="ai-assist-quick-action" style={{display: 'none'}}>
                <div style={{ fontSize: "18px" }}>Quick Action Links</div>
                <hr />
                <QuickActionLinks actionItems={actionItemsList[language]} />
            </div>
            <div className="ai-assit-container">
                <Content />
            </div>
        </div>
    );
};

export default AiMessages;
