import React from "react";
import ReleaseNotes from "../../components/release-notes/ReleaseNotes";
import {
    EonUiAccordion,
    EonUiAccordionPane,
    EonUiTab,
    EonUiTabs,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import {
    aboutUsHeading,
    aboutUsSubHeading,
    faqText,
    headerTextData,
    tipsAndTricksText,
    whatsNewText,
} from "../../components/release-notes/AboutUsPageData";
import About_me from "../../assets/image/about-me.jpg";
import "./AboutUs.scss";

const TextWithList: React.FC<{ text: string }> = ({ text }) => (
    <>
        {text.split("\n").map((line, lineIndex) => (
            <span key={lineIndex}>
                {line.includes("*") ? (
                    <ul>
                        {line
                            .split("*")
                            .map((item, itemIndex) =>
                                item.trim() ? (
                                    <li key={itemIndex}>{item.trim()}</li>
                                ) : null
                            )}
                    </ul>
                ) : (
                    <p>{line}</p>
                )}
            </span>
        ))}
    </>
);

const HeaderSection: React.FC<{ language: string }> = ({ language }) => (
    <div>
        <div className="aboutme-header-section">
            <div className="aboutme-image">
                <img className="about-me" src={About_me} alt="about-me" />
            </div>
            <div className="aboutme-header-label">
                {aboutUsHeading[language].map((text, index) => (
                    <div className="looking-label" key={index}>
                        {text}
                    </div>
                ))}
                {aboutUsSubHeading[language].map((text, index) => (
                    <div className="find-out-label" key={index}>
                        {text}
                    </div>
                ))}
            </div>
        </div>
        {headerTextData[language].map((text, index) => (
            <div key={index}>
                <TextWithList text={text} />
            </div>
        ))}
    </div>
);

const FaqSection: React.FC<{ language: string }> = ({ language }) => (
    <EonUiAccordion mode="single" key={language} data-testid="releaseNotes">
        {faqText[language].map((notes, index) => (
            <EonUiAccordionPane
                pane-title={notes.question}
                slot={notes.question}
                key={`${index}`}
            >
                <div>
                    <TextWithList text={notes.answer} />
                </div>
            </EonUiAccordionPane>
        ))}
    </EonUiAccordion>
);

const TipsAndTricksSection: React.FC<{ language: string }> = ({ language }) => (
    <>
        {tipsAndTricksText[language].map((tip, index) => (
            <div key={index}>
                {tip.split("\n\n").map((paragraph, pIndex) =>
                    paragraph.includes("*") ? (
                        <ul key={pIndex}>
                            {paragraph.split("\n").map((line, lIndex) => (
                                <li key={lIndex}>
                                    {line.replace("*", "").trim()}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p key={pIndex}>{paragraph}</p>
                    )
                )}
            </div>
        ))}
    </>
);

const WhatsNewSection: React.FC<{ language: string }> = ({ language }) => (
    <ul>
        {whatsNewText[language].map((item, index) => (
            <li key={index}>
                <b>{item.split(":")[0]} : </b>
                {item.split(":")[1]}
            </li>
        ))}
    </ul>
);

const AboutUs: React.FC = () => {
    const { t, i18n } = useTranslation("about-us");

    return (
        <>
            <HeaderSection language={i18n.language} />
            <EonUiTabs
                active-tab-index="0"
                active-tab-id=""
                description="Short description of the tabs"
                inner-background="eon-lightgrey"
                key={i18n.language}
            >
                <EonUiTab header={t("releaseNotes.faq")} tab-id="faq">
                    <FaqSection language={i18n.language} />
                </EonUiTab>
                <EonUiTab
                    header={t("releaseNotes.tipsAndTricks")}
                    tab-id="tipsAndTricks"
                >
                    <TipsAndTricksSection language={i18n.language} />
                </EonUiTab>
                <EonUiTab header={t("releaseNotes.whatsNew")} tab-id="whatsNew">
                    <WhatsNewSection language={i18n.language} />
                </EonUiTab>
                <EonUiTab
                    header={t("releaseNotes.tabName")}
                    tab-id="releaseNotes"
                >
                    <div data-testid="aboutUs">
                        <ReleaseNotes />
                    </div>
                </EonUiTab>
            </EonUiTabs>
        </>
    );
};

export default AboutUs;
