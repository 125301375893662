import React from "react";
import TableComponent from "../../generic-components/data-table/TableComponent";
import { MessageWithActionAndData } from "../AiAssist.context";
import { useTranslation } from "react-i18next";
import tableHeaderLabel from "../../../pages/service-request/tableHeaderLabel.json";
import { ReactComponent as AiIcon } from "../../../assets/image/ai-icon32.svg";
import TypingEffect from "../utils/typing-effect/TypingEffect";

const serviceTableFields = ["number", "description", "state"];
type TableLabelData = {
    [lang: string]: {
        [key: string]: string;
    };
};

const tableHeaderData: TableLabelData = tableHeaderLabel;

const showIncidentsOrOrders: React.FC<MessageWithActionAndData> = ({
    data,
    content,
}) => {
    const { i18n } = useTranslation();
    const language = i18n.language;

    return (
        <div className="bot-header hihihi">
            <div className="eon-logo">
                <AiIcon />
            </div>
            <div className="bot-reply-container">
                <span className="bot-heading">AI Assistant</span>
                <TypingEffect className="bot-text" text={content} speed={1} />
                {data && (
                    <TableComponent
                        showPagination={false}
                        rows={data}
                        cols={serviceTableFields.map(
                            (field) => tableHeaderData[language][field]
                        )}
                        headerMaps={serviceTableFields}
                    />
                )}
            </div>
        </div>
    );
};

export default showIncidentsOrOrders;
