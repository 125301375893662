import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LiveAgentChat.scss";
import UserIcon from "../../assets/image/user.svg";
import { closeChatApi, createChatApi, getChatApi, updateChatApi } from "../../services/liveAgent";
import { generateUUID } from "../feedback/sessionId";
import { EonUiButton, EonUiForm, EonUiIcon, EonUiInput, EonUiLink } from "@eon-ui/eon-ui-components-react";
import { AiAssistContext } from "../ai-assist-refactored/AiAssist.context";
import useSearchStore from "../../store/searchStore";
import { getSnowBearerTokenLiveAgentChat } from "../../util/localstorage";
import { getFormattedDateTime } from "../../util/date";

type MessageType = {
    sender: "user" | "agent";
    message: string;
    timestamp: string;
    opCode?: 100 | 110 | 200;
    attachmentName?: string;
    attachmentUrl?: string;
};

const LiveAgentChat = () => {

    const {
        setShowLiveAgentChat
    } = useSearchStore();

    const { handleResetChat } = useContext(AiAssistContext);

    const [question, setQuestion] = useState<string>("");
    const [localSessionId, setLocalSessionId] = useState<string>("");
    const [conversationId, setConversationId] = useState<string>("");
    const [showConnectingMsg, setShowConnectingMsg] = useState<boolean>(false);
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [agentLastMessageTimestamp, setAgentLastMessageTimestamp] = useState<string>(new Date(0).toISOString());
    const [showAgentLeft, setShowAgentLeft] = useState<boolean>(false);
    const [connectingLoader, setConnectingLoader] = useState<string>("");

    const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const intervalIdLoaderRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const agentLastMessageTimestampRef = useRef(agentLastMessageTimestamp);
    const connectingLoaderRef = useRef(connectingLoader);
    const GET_API_INTERVAL = 5000;
    const MESSAGE_CODE = 100;
    const MESSAGE_ATTACHMENT_CODE = 110;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { t, i18n } = useTranslation();

    useEffect(() => {
        setLocalSessionId(generateUUID());
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
            if (intervalIdLoaderRef.current) {
                clearInterval(intervalIdLoaderRef.current);
            }
        };
    }, []);

    useEffect(() => {
        localSessionId && createChat("Hi");
    }, [localSessionId]);

    useEffect(() => {
        agentLastMessageTimestampRef.current = agentLastMessageTimestamp;
    }, [agentLastMessageTimestamp]);

    useEffect(() => {
        connectingLoaderRef.current = connectingLoader;
    }, [connectingLoader]);

    const getKID = () => {
        const msalIdData = localStorage.getItem("msal.account.keys") || "";
        const msalID = JSON.parse(msalIdData)[0] || "";
        const userEmail = JSON.parse(localStorage.getItem(msalID) || "")?.username;
        const userKID = userEmail.split("@")[0];
        return userKID;
    };

    const getCategory = () => {
        return "IT Requests & Other Topics";
    };

    const getClientData = () => {
        return {
            sessionId: localSessionId,
            endpointType: "Me@Eon"
        };
    };

    const chatSend = () => {
        if (!question.trim()) {
            return;
        }
        if (conversationId) {
            updateChat(conversationId, question);
        } else {
            createChat(question);
        }
        updateMessage([{ sender: "user", message: question, timestamp: getFormattedDateTime(undefined, "H:i", true, timezone) }]);
        setQuestion("");
    };

    const updateMessage = (newMessages: MessageType[]) => {
        setMessages((prevMessages: MessageType[]) => [...prevMessages, ...newMessages]);
    };

    const getChat = async () => {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            const payload = {
                sessionId: localSessionId,
                timestamp: agentLastMessageTimestampRef.current
            };
            const response = await getChatApi(payload, headers);
            for(const resp of response) {
                if (resp.messages.message || resp.attachmentName) {
                    setShowConnectingMsg(false);
                    if (intervalIdLoaderRef.current) {
                        clearInterval(intervalIdLoaderRef.current);
                        intervalIdLoaderRef.current = null;
                    }
                    updateMessage([{
                        sender: "agent",
                        message: resp.messages.message,
                        timestamp: getFormattedDateTime(new Date(resp.messages.timestamp).getTime(), "H:i", true, timezone),
                        opCode: resp.opCode ? resp.opCode : (resp.attachmentName ? MESSAGE_ATTACHMENT_CODE : MESSAGE_CODE),
                        attachmentName: resp.attachmentName,
                        attachmentUrl: resp.attachmentUrl
                    }]);
                    if (new Date(resp.messages.timestamp).getTime() > new Date(agentLastMessageTimestampRef.current).getTime()) {
                        setAgentLastMessageTimestamp(resp.messages.timestamp);
                    }
                }
                if (resp.opCode === 200) {
                    setConversationId("");
                    if (intervalIdRef.current) {
                        clearInterval(intervalIdRef.current);
                        intervalIdRef.current = null;
                    }
                    setShowAgentLeft(true);
                    break;
                }
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    const showConnectingLoader = () => {
        intervalIdLoaderRef.current = setInterval(() => {
            if (connectingLoaderRef.current.match(/\./g)?.length == 5) {
                setConnectingLoader("");
            }
            setConnectingLoader((value: string) => value + " .");
        }, 500);
    };

    const createChat = async (message: string) => {
        setShowConnectingMsg(true);
        showConnectingLoader();
        const headers = {
            Authorization: `Bearer ${getSnowBearerTokenLiveAgentChat()}`,
            "Content-Type": "application/json",
        };
        const payload = {
            caller_id: getKID(),
            category: getCategory(),
            language: i18n.language,
            message: message,
            clientData: getClientData()
        };
        try {
            const response = await createChatApi(payload, headers);
            if (response.result.status === "200") {
                setConversationId(response.result.conversation_id);
                intervalIdRef.current = setInterval(() => {
                    getChat();
                }, GET_API_INTERVAL);
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    const updateChat = async (convId: string, message: string) => {
        if (!convId) return;
        const headers = {
            Authorization: `Bearer ${getSnowBearerTokenLiveAgentChat()}`,
            "Content-Type": "application/json",
        };
        const payload = {
            conversation_id: convId,
            caller_id: getKID(),
            message: message,
            clientData: getClientData()
        };
        try {
            const response = await updateChatApi(payload, headers);
            if (response.result.status === "200") {
                // chat updated successfully
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    const closeChat = async (convId: string) => {
        if (!convId) return;
        const headers = {
            Authorization: `Bearer ${getSnowBearerTokenLiveAgentChat()}`,
            "Content-Type": "application/json",
        };
        const payload = {
            conversation_id: convId,
            caller_id: getKID(),
            clientData: getClientData()
        };
        try {
            const response = await closeChatApi(payload, headers);
            if (response.result.status === "200") {
                // chat closed successfully
            }
        } catch (e) {
            console.log("something went wrong!", e);
        }
    };

    return (
        <div className="live-agent-chat-container">
            <div className="heading">

                    <div className="user-logo">
                        <img src={UserIcon} alt="userIcon"/>
                    </div>
                    <div className="user-reply-container">
                        <span className="user-heading">{t("chatBot.liveAgentChat.connectWithLiveAgent")}</span>
                    </div>

            </div>

            { showConnectingMsg && (
            <div className="agent-waiting-msg">
                <div className="waiting-header">
                    <div className="user-logo">
                        <EonUiIcon
                            size="normal"
                            name={"messenger_01"}
                            className="agent-icon eon-ui-svg-icon"
                        />
                    </div>
                    <div className="user-reply-container">
                        <span className="user-heading loader">{t("chatBot.liveAgentChat.connectingWithLiveAgent")}{connectingLoader}</span>
                        <span className="bot-text">{t("chatBot.liveAgentChat.connectingHint")}</span>
                    </div>
                </div>
            </div>
            )}

            <div className="messages">
                {messages.map((msg: MessageType, key: number) => (
                    <div className={msg.sender === "user" ? "user-message" : "agent-message"} key={key}>
                        <div className={msg.sender === "user" ? "user-header" : "agent-header"}>
                            <div className="user-logo">
                                { msg.sender === "user" ? (
                                    <img src={UserIcon} alt="userIcon" />
                                ) : (
                                    <EonUiIcon
                                        size="normal"
                                        name={"messenger_01"}
                                        className="agent-icon eon-ui-svg-icon"
                                    />
                                )}
                            </div>
                            <div className="user-reply-container ">
                                <span className="user-heading">
                                    {msg.opCode === MESSAGE_ATTACHMENT_CODE ? (
                                        <EonUiLink
                                            text={msg.attachmentName}
                                            title={msg.attachmentName}
                                            target="_blank"
                                            hideIcon={true}
                                            size="small"
                                            href={msg.attachmentUrl}
                                        />
                                    ) : (
                                        msg.message
                                    )}
                                </span>
                            </div>
                            <span className="message-time">{msg.timestamp}</span>
                        </div>
                    </div>
                ))}
            </div>

            { showAgentLeft ? (
                <div className="agent-waiting-msg">
                    <div className="waiting-header">
                        <div className="user-logo">
                            <EonUiIcon
                                size="normal"
                                name={"messenger_01"}
                                className="agent-icon eon-ui-svg-icon"
                            />
                        </div>
                        <div className="user-reply-container">
                            <span className="user-heading">{t("chatBot.liveAgentChat.agentLeftMsg")}</span>
                        </div>
                    </div>
                </div>
            ) : (
                conversationId && <div className="chat-input-box">
                    <EonUiForm onFormSubmit={chatSend} className="form">
                        <EonUiInput
                            placeholder={t("chatBot.liveAgentChat.typeMessageHint")}
                            className="chat-input"
                            value={question}
                            type="text"
                            onValueChanged={(e) =>
                                setQuestion(e.target.value)
                            }
                            size="normal"
                        />
                    </EonUiForm>
                    <EonUiButton
                        title={t("chatBot.sendbtn")}
                        placeholder={""}
                        onClick={() => {
                            chatSend();
                        }}
                        className="margin-top-space"
                        icon="send"
                    ></EonUiButton>
                </div>
            )}

            <div className="switch-to-assistance-btn">
                <EonUiLink
                    onClick={() => {
                        closeChat(conversationId);
                        setConversationId("");
                        if (intervalIdRef.current) {
                            clearInterval(intervalIdRef.current);
                            intervalIdRef.current = null;
                        }
                        handleResetChat();
                        setShowLiveAgentChat(false);
                    }}
                    hideIcon={true}
                    title={t("chatBot.liveAgentChat.switchToAssistance")}
                    text={t("chatBot.liveAgentChat.switchToAssistance")}
                    size="small"
                ></EonUiLink>
            </div>
        </div>
    );
};

export default LiveAgentChat;
