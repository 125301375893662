interface ContentDataInterface {
    en: string[];
    de: string[];
    [key: string]: string[];
}

interface ReleaseNotesDataInterface {
    version: string;
    date: string;
    content: ContentDataInterface;
}

interface FaqDataInterface {
    [key: string]: questionAnswerInterface[];
}

interface questionAnswerInterface {
    question: string;
    answer: string;
}

export const AboutUsPageData: ReleaseNotesDataInterface[] = [
    {
        version: "1.0.0",
        date: "24.06.2024",
        content: {
            en: [
                "General Platform - UI Design",
                "Search and linking of applications",
                "Functions of Tiles & Tabs",
                "SNOW Integration",
                "User Feedback",
            ],
            de: [
                "Allgemeine Plattform - UI-Design",
                "Suche und Verknüpfung von Anwendungen",
                "Funktionen von Kacheln und Registerkarten",
                "SNOW-Integration",
                "Benutzerfeedback",
            ],
        },
    },
    {
        version: "1.1.0",
        date: "29.07.2024",
        content: {
            en: [
                "Conducting a guided tour on the Tile Page",
                "About me@eon and release version",
                "Brand Evolution Landing Page/Tiles",
                "Brand Evolution Header",
                "Brand Evolution Footer",
            ],
            de: [
                "Durchführen einer geführten Tour auf der tile seite",
                "Über me@eon und Release-Version",
                "Markenentwicklung Landing Page / Kacheln",
                "Markenentwicklung Header",
                "Markenentwicklung Fußzeile",
            ],
        },
    },
    {
        version: "1.2.0",
        date: "30.08.2024",
        content: {
            en: [
                "Addition of new Applications (Gertrud, Notification Portal, Zuko, EON Brand Space, PKI Portal, Mail DL, Journeys/Events, MyGenius).",
                "Meeting room additional features to show current booking and modify/cancel.",
            ],
            de: [
                "Hinzufügen neuer Applications (Gertrud, Meldungsportal, Zuko, EON Brand Space, PKI Portal, Mailverteiler, Fahrten und Veranstalltungen, MyGenius).",
                "Zusätzliche Raumbuchungen Funktionen zum Anzeigen der aktuellen Buchung und zum Ändern/Stornieren.",
            ],
        },
    },
    {
        version: "2.0.0",
        date: "11.12.2024",
        content: {
            en: [
                "me@eon UI Interface enhancements for V2.0",
                "Simplified Meeting Room Booking UI-Phase 2",
                "AI Assistant for IT Support queries",
                "Create Incident through AI Assistant",
                "Live Agent integration with AI Assistant",
                "Feedback loop and enhancements 2.0",
            ],
            de: [
                "me@eon Verbesserungen für die Benutzeroberfläche V2.0",
                "Vereinfachte Benutzeroberfläche für die Buchung von Besprechungsräumen - Phase 2",
                "KI-Assistent für IT-Support-Anfragen",
                "Erstellung von Vorfällen mithilfe von KI-Assistent",
                "Live-Agentenintegration mit KI-Assistent",
                "Feedback-Schleife und Verbesserungen 2.0",
            ],
        },
    },
];

export const faqText: FaqDataInterface = {
    en: [
        {
            question: "What is me@eon?",
            answer: "me@eon is your central platform for a company-wide search, IT support and assistance in your different typical work tasks. It can find a specific information, help you solve a work-related task, answer your questions and guide you through an IT issue so that you can quickly resume your work. Just start by searching for a keyword or asking a question.",
        },
        {
            question: "What can me@eon do?",
            answer: "me@eon is your personal assistant readily available and personalized just for you. \n With me@eon you can: \n * Search for a fact, a piece of information, a definition or even a document which is stored in your different systems, for example JIRA, Confluence, Sharepoint, etc. \n * Search for an internal workflow which is typical for your role, for example booking a work trip, reserving a meeting room or process an order. Me@eon will help you find and access this workflow and you can bookmark it on your dashboard for easy access. \n * Get assistance for an IT issue providing you a real and expert-verified solution to troubleshoot an IT problem, configure settings of your applications or connect you to an IT expert. \n me@eon offers you relevant and helpful answers so that you quickly and effortlessly get the result you were looking for.",
        },
        {
            question: "How should I talk to me@eon?",
            answer: "You can search for specific words, phrases or ask me@eon questions in your preferred language. Me@eon will look for the most relevant answer and offer you a combination of results. \n * Search in me@eon in a way that feels natural to how you talk, text, or think. Get a single, summarized answer with a consolidated set of links, or a detailed response and sources for more complex questions. \n * Ask follow-up questions to your initial question or search to get more helpful replies. \n * Rephrase the response and make things easier to understand. Just say something like, “Can you explain that more simply?”",
        },
        {
            question: "Which workflows (apps) are available on me@eon?",
            answer: "Your experience in me@eon is personalized. This means that there are different apps and workflows for you depending on your organization, role and location. The goal is to offer you the most frequently used workflows in one place. \n Depending on your role and access rights you can access following applications directly from me@eon: \n * CATS (time tracking): you can easily track and manage your work hours and activities; \n * Purchase requisition: find all relevant functions to request goods and services; \n * MyInbox: manage and approve your SAP related approvals; \n * Reset SAP password: reset access passwords for your SAP system: \n * Concur: book, manage your trips and submit your travel expense reports; \n * GINGCO (Meeting room booking): book meeting rooms and work places; \n * Prisma: report and manage unsafe situations and incidents; \n * Beeline: manage workforce and streamline staffing processes; \n * MyHR: find and access MyHR portal for all HR-related information; \n * My Assets, Orders and Incidents for managing your respective assets, orders and incidents easily. \n * Gertrud: prepare for board meetings; \n * Facility services: raise tickets relates to facility services; \n * Zuko: plan and organize movement from one office premises to another; \n * Brand space: access all brand ressources like presentations, images and guidelines; \n * PKI Portal: manage your PKI certificates; \n * Mail distribution list: create and maintain email distribution lists. \n * MyGenius: find different learning materials, trainings and courses.",
        },
        {
            question: "What types of information can me@eon help me find?",
            answer: "You can find the following on me@eon: \n * Information, facts, definitions from all over the company; \n * Typical internal workflows and apps; Knowledge base articles and different trainings from MyGenius; \n * Your documents from different sources like Confluence, JIRA, Sharepoint, Gitlab and others; \n * Instructions, tutorials and step-by-step guides for different IT-related issues; \n * Information from the Internet gathered with the help of our AI assistance.",
        },
        {
            question: "Which ressources does me@eon search?",
            answer: "me@eon searches different internal sources to find the most relevant and accurate information for you. Using assistance you can also ask me@eon to help you find an answer using the internet.",
        },
        {
            question: "What is me@eon assistance and how does it work?",
            answer: "Internal content in me@eon is created and maintained by people from the organization, employees just like you. When you search for something in me@eon AI technology is used to find the exact information you need and to summarize it for you. \n me@eon is your personal gardener who weeds out unimportant and outdated information and offers you the most accurate and relevant answer to your search.",
        },
        {
            question: "How can I improve the quality of me@eon answers?",
            answer: "You can improve me@eon with every interaction by approving good content and disproving inaccurate or unhelpful information. Me@eon can learn from interactions becoming more and more accurate over time for you and your colleagues. Your every interaction improves me@eon, please give a vote for every answer you get.",
        },
        {
            question:
                "Will my conversations in me@eon be visible to other users?",
            answer: "No, me@eon handles all questions and answers anonymously. You can ask me@eon anything that interests you, there are no silly questions. Your conversations with the assistance are private. The upvotes and downvotes you provide are not associated with your account and are handled by the system anonymously.",
        },
    ],
    de: [
        {
            question: "Was ist me@eon?",
            answer: "me@eon ist Deine zentrale Plattform für eine unternehmensweite Suche, IT-Unterstützung und Hilfe bei Deinen verschiedenen typischen Arbeitsaufgaben. Es kann eine bestimmte Information finden, Dir bei der Lösung einer arbeitsbezogenen Aufgabe helfen, Deine Fragen beantworten und dir bei einem IT-Problem helfen, damit Du Deine Arbeit schnell wieder aufnehmen kannst. Beginne einfach mit der Suche nach einem Stichwort oder stelle eine Frage.",
        },
        {
            question: "Was kann me@eon tun?",
            answer: "me@eon ist Dein persönlicher Assistent, der nur für dich zur Verfügung steht und personalisiert ist. Mit me@eon kannst Du: \n * Nach einem Fakt, einer Information, einer Definition oder sogar einem Dokument suchen, das in deinen verschiedenen Systemen gespeichert ist, z.B. JIRA, Confluence, Sharepoint, etc.\n * Suche nach einem internen Workflow, der typisch für deine Rolle ist, z.B. das Buchen einer Dienstreise, das Reservieren eines Besprechungsraums oder die Bearbeitung einer Bestellung. me@eon hilft dir, diesen Workflow zu finden und aufzurufen, und du kannst ihn auf deinem Dashboard mit einem Lesezeichen versehen, um leicht darauf zugreifen zu können.\n * Hole dir Unterstützung bei einem IT-Problem, indem du eine von Experten geprüfte Lösung für ein IT-Problem erhältst, Einstellungen deiner Anwendungen konfigurierst oder eine Verbindung zu einem IT-Experten herstellst.\n me@eon bietet Dir relevante und hilfreiche Antworten, damit du schnell und mühelos das gewünschte Ergebnis erhältst.",
        },
        {
            question: "Wie soll ich mit me@eon sprechen?",
            answer: 'Du kannst nach bestimmten Wörtern und Phrasen suchen oder me@eon Fragen in deiner bevorzugten Sprache stellen. me@eon wird nach der relevantesten Antwort suchen und dir eine Kombination von Ergebnissen anbieten.\n * Suche in me@eon auf eine Weise, die sich natürlich anfühlt, wie du sprichst, schreibst oder denkst. Erhalte eine vollständige zusammengefasste Antwort mit einer Reihe von Links und einer detaillierten Antwort für komplexere Fragen mit entsprechenden Quellen.\n * Stelle Folgefragen zu deiner ursprünglichen Suchanfrage, um weiterführende hilfreichere Antworten zu erhalten.\n * Formuliere die Antwort um und mache die Dinge einfacher zu verstehen. Sage einfach etwas wie "Kannst du das einfacher erklären?"',
        },
        {
            question: "Welche Workflows (Apps) sind auf me@eon verfügbar?",
            answer: "Deine Erfahrung in me@eon ist personalisiert. Das bedeutet, dass es je nach deiner Organisation, Rolle und Standort verschiedene Apps und Workflows für dich gibt. Das Ziel ist es, dir die am häufigsten verwendeten Workflows an einem Ort anzubieten.\n Je nach deiner Rolle und Zugriffsrechten kannst du direkt über me@eon auf folgende Anwendungen zugreifen:\n * CATS (Zeiterfassung): Du kannst deine Arbeitsstunden und Aktivitäten einfach eintragen und verwalten;\n * Bestellanforderung: Finde alle relevanten Funktionen, um Waren und Dienstleistungen anzufordern;\n * MyInbox: Verwalte und genehmige deine SAP-bezogenen Genehmigungen;\n * SAP-Passwort zurücksetzen: Setze Zugangspasswörter für dein SAP-System zurück;\n * Concur: Buche, verwalte deine Reisen und reiche deine Reisekostenabrechnungen ein;\n * GINGCO (Buchung von Besprechungsräumen): Buche Besprechungsräume und Arbeitsplätze;\n * Prisma: Melde unsichere Situationen und Vorfälle und verwalte sie;\n * Beeline: Verwalte die Arbeitskräfte und optimiere Personalprozesse;\n * MyHR: Finde und greife auf das MyHR-Portal für alle personalbezogenen Informationen zu;\n * My Assets, Orders und Incidents zur einfachen Verwaltung deiner entsprechenden Beschaffungen, Bestellungen und Vorfälle.\n * Gertrud: Bereite dich auf Vorstandssitzungen vor;\n * Facility Services: Erstelle Tickets für Facility-Services;\n * Zuko: Plane und organisiere den Umzug von einem Bürostandort zum anderen;\n * Brand Space: Greife auf alle Markenressourcen wie Präsentationen, Bilder und Richtlinien zu;\n * PKI-Portal: Verwalte deine PKI-Zertifikate;\n * Verteilerliste für E-Mails: Erstelle und pflege E-Mail-Verteilerlisten.\n * MyGenius: Finde verschiedene Lernmaterialien, Schulungen und Kurse.",
        },
        {
            question: "Welche Arten von Informationen kann me@eon finden?",
            answer: "Du kannst auf me@eon folgende Informationen finden:\n * Informationen, Fakten, Definitionen aus dem gesamten Unternehmen;\n * Typische interne Workflows und Apps;\n * Wissensdatenbankartikel und verschiedene Schulungen von MyGenius;\n * Deine Dokumente aus verschiedenen Quellen wie Confluence, JIRA, Sharepoint, Gitlab und anderen;\n * Anleitungen, Tutorials und Schritt-für-Schritt-Anweisungen für verschiedene IT-bezogene Probleme;\n * Informationen aus dem Internet, die mit Hilfe unserer KI-Assistenz gesammelt wurden.",
        },
        {
            question: "Welche Quellen durchsucht me@eon?",
            answer: "me@eon durchsucht verschiedene interne Quellen, um die wichtigsten und genauesten Informationen für Dich zu finden. Mit Hilfe der Assistenz kannst Du me@eon auch bitten, Dir bei der Suche nach einer Antwort im Internet zu helfen.",
        },
        {
            question: "Was ist me@eon Assistance und wie funktioniert es?",
            answer: "Die Inhalte in me@eon werden von Personen aus der Organisation erstellt und gepflegt, von Mitarbeitern wie dir. Wenn du etwas in me@eon suchst, wird KI-Technologie verwendet, um die genauen Informationen zu finden, die du benötigst, und sie für dich zusammenzufassen.\n me@eon ist dein persönlicher Gärtner, der unwichtige und veraltete Informationen aussortiert und dir die genaueste und relevanteste Antwort auf deine Suche bietet.",
        },
        {
            question:
                "Wie kann ich die Qualität der me@eon-Antworten verbessern?",
            answer: "Du kannst me@eon bei jeder Interaktion verbessern, indem du gute Inhalte positiv bewertest und ungenaue oder unhilfreiche Informationen entsprechend meldest. Dafür stehen dir die Schaltflächen „Daumen hoch“ und „Daumen runter“ zur Verfügung. Me@eon kann aus Interaktionen lernen und im Laufe der Zeit immer genauer für dich und deine Kollegen werden.\n Jede deiner Interaktionen verbessert me@eon, bitte gib für jede Antwort, die du erhältst, eine Bewertung ab.",
        },
        {
            question:
                "Werden meine Unterhaltungen in me@eon für andere Benutzer sichtbar sein?",
            answer: "Nein, me@eon behandelt alle Fragen und Antworten anonym. Du kannst me@eon alles fragen, was dich interessiert, es gibt keine komischen Fragen. Deine Unterhaltungen mit der Assistenz sind privat. Die von dir abgegebenen Bewertungen sind nicht mit deinem Konto verknüpft und werden anonym vom System behandelt.",
        },
    ],
};

export const tipsAndTricksText: ContentDataInterface = {
    en: [
        "* Use specific keywords: When searching for information, try to use specific keywords that are relevant to your query. This will help me@eon to narrow down the search results and provide more accurate and useful information for you. \n\n * Explore related content: me@eon provides suggestions for related questions and content based on your search query. These suggestions can help you discover additional information that may be relevant to your needs. Be sure to explore these related content recommendations to broaden your knowledge. \n\n * Use favourites: When you find a workflow for a common task which you often do, for example booking meeting rooms, add this app to your dashboard using the plus symbol. Afterwards the app will appear on your dashboard and you can access it every time you open me@eon. \n\n * Utilize advanced search: Use assistance to help you solve your queries and different IT-related issues, walk your through problems, help you troubleshoot issues with your hardware, software or any other issues. Ask follow-up questions, it knows a lot about internal processes and can help find you just the information you need.\n\n * Filter and sort search results: based on various criteria such as date, relevance, file type, or author. Take advantage of these features to quickly find the most relevant information for you.\n\n * Provide feedback: If you find that the search results are not meeting your expectations or if you have suggestions for improvement, provide feedback by using upvote and downvote symbols under each answer. This feedback can help us enhance the search algorithms and provide better results in the future.",
    ],
    de: [
        "* Verwende spezifische Schlüsselwörter: Wenn du nach Informationen suchst, versuche spezifische Schlüsselwörter zu verwenden, die relevant für deine Anfrage sind. Dies hilft me@eon, die Suchergebnisse einzugrenzen und dir genauere und nützlichere Informationen zu liefern. \n\n * Erkunde verwandte Inhalte: me@eon bietet Vorschläge für verwandte Fragen und Inhalte basierend auf deiner Suchanfrage. Diese Vorschläge können dir helfen, zusätzliche Informationen zu entdecken, die für deine Bedürfnisse relevant sein können. Erkunde diese Empfehlungen für verwandte Inhalte, um dein Wissen zu erweitern. \n\n * Verwende Favoriten: Wenn du einen Workflow für eine häufige Aufgabe findest, die du oft ausführst, zum Beispiel das Buchen von Besprechungsräumen, füge diese App mit dem Plus-Symbol zu deinem Dashboard hinzu. Danach wird die App auf deinem Dashboard angezeigt und du kannst jederzeit darauf zugreifen, wenn du me@eon öffnest. \n\n * Nutze die erweiterte Suche: Nutze die Assistenz, um dir bei verschiedenen Problemen zu helfen, dich durch Probleme zu führen, dir bei der Fehlerbehebung von Hardware, Software oder anderen Problemen zu helfen. Stelle Nachfragen, me@eon kennt sich gut mit internen Prozessen aus und kann dir genau die Informationen liefern, die du benötigst. \n\n * Filtere und sortiere Suchergebnisse: basierend auf verschiedenen Kriterien wie Datum, Relevanz, Dateityp oder Autor. Nutze diese Funktionen, um schnell die relevantesten Informationen für dich zu finden. \n\n * Gib Feedback: Wenn du feststellst, dass die Suchergebnisse nicht deinen Erwartungen entsprechen oder wenn du Verbesserungsvorschläge hast, gib Feedback, indem du die 'Daumen hoch'- und 'Daumen runter'-Symbole unter jeder Antwort verwendest. Dieses Feedback kann uns helfen, die Suchalgorithmen zu verbessern und in Zukunft bessere Ergebnisse zu liefern.",
    ],
};

export const whatsNewText: ContentDataInterface = {
    en: [
        "Unified Search: Our new unified search displays different types of results in one page, making it easier for you to find what you need.",
        "Improved search: Narrow down your search with our platform suggestions. As you type, me@eon provides intelligent suggestions to help you refine your search and find the most relevant results.",
        "Improvements for booking a meeting room: We have improved the meeting room booking process, making it easier and more efficient. Find and book available rooms with just a few clicks.",
    ],
    de: [
        "Vereinheitlichte Suche: Unsere neue vereinheitlichte Suchfunktion zeigt verschiedene Arten von Ergebnissen auf einer Seite an, so dass Du  das leichter findest, was Du brauchst.",
        "Verbesserte Suche: Schränke deine Suche mit Vorschlägen ein. Während Du tippst, liefert me@eon intelligente Vorschläge, die Dir helfen, deine Suche zu verfeinern und die relevantesten Ergebnisse zu finden.",
        "Verbesserungen bei der Buchung von Besprechungsräumen: Wir haben den Buchungsprozess für besprechungsräume verbessert, um ihn einfacher und effizienter zu gestalten. Finde und buche verfügbare Räume mit nur wenigen Klicks.",
    ],
};

export const headerTextData: ContentDataInterface = {
    en: [
        "me@eon can help you find information, solve an IT issue or answer your questions about internal topics and general topics from the web. \n\n me@eon is here to provide you guidance, support and simplify your search experience. \n * Find and access your most useful apps in me@eon \n * Search for information from different sources \n * Ask me@eon a question and get a straightforward answer",
    ],
    de: [
        "me@eon kann dir helfen, Informationen zu finden, ein IT-Problem zu lösen oder deine Fragen zu internen Themen und allgemeinen Themen aus dem Web zu beantworten. \n\n me@eon ist hier, um dir Anleitung, Unterstützung und Vereinfachung deiner Sucherfahrung zu bieten. \n * Finde und greife auf deine nützlichsten Apps in me@eon zu \n * Suche nach Informationen aus verschiedenen Quellen \n * Stelle me@eon eine Frage und erhalte eine klare Antwort",
    ],
};
export const aboutUsHeading: ContentDataInterface = {
    en: ["me@eon is here to help you find exactly what you are looking for"],
    de: ["me@eon ist hier, um dir genau das zu finden, wonach du suchst"],
};

export const aboutUsSubHeading: ContentDataInterface = {
    en: ["Find out more about what me@eon can do for you"],
    de: ["Erfahren Sie mehr darüber, was me@eon für Sie tun kann"],
};
